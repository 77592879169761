<template>
  <div>
    <!-- 头部 -->
    <div
      class="page-header"
      :style="{ borderBottom: showBottom == true ? '1px solid #ccc' : 'none' }"
    >
      <el-row>
        <el-col :span="12">
          <div class="navbar-header">
            <router-link to="/index">
              <img src="../../assets/imgs/logo.png" />
            </router-link>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="bug hidden-sm-and-up">
            <a @click="showMenu"><i class="el-icon-menu menu"></i></a>
          </div>
          <div class="header-right hidden-xs-only">
            <ul>
              <router-link to="/index">
                <li @click="changeActive(1)">
                  <a :class="{ active: isActive == 1 }" href="#">产品</a>
                </li>
              </router-link>

              <router-link to="/case">
                <li @click="changeActive(2)">
                  <a :class="{ active: isActive == 2 }" href="#">案例</a>
                </li>
              </router-link>

              <router-link to="/pricing">
                <li @click="changeActive(3)">
                  <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
                </li>
              </router-link>
              <li>
                <div id="user" v-if="isLogin">
                  <router-link to="/home">
                    <span class="into">
                      进入系统
                      <i class="el-icon-right"></i>
                    </span>
                  </router-link>
                  <i class="iconfont icon-user"></i>
                  <a id="userName">{{ userName }}</a>
                </div>
                <div v-else>
                  <el-button @click="goRegister" class="register">
                    注册
                  </el-button>

                  <el-button type="primary" @click="goLogin" class="login">
                    登录
                  </el-button>
                </div>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="menuBox" v-show="menuBox">
      <ul>
        <router-link to="/index">
          <li @click="changeActive(1)">
            <a :class="{ active: isActive == 1 }" href="#">产品</a>
          </li>
        </router-link>

        <router-link to="/case">
          <li @click="changeActive(2)">
            <a :class="{ active: isActive == 2 }" href="#">案例</a>
          </li>
        </router-link>

        <router-link to="/pricing">
          <li @click="changeActive(3)">
            <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
          </li>
        </router-link>
      </ul>
    </div>

    <!-- 内容 -->
    <div class="nav container">
      <div class="section-case">
        <div class="section-header">
          <h1 class="title">他们正在使用锐研</h1>
          <p class="desc">与我们的客户一起挖掘互联网大数据金矿</p>
        </div>
        <div class="case-grids">
          <el-row>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/case01.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">南京大学</p>
                    <p class="case-name">南京双创新闻数据库</p>
                  </div>
                </div>
                <div class="case-desc">
                  南京大学双创新闻数据库基于锐研云采集平台采集国家网信办公布的380家网站的新闻数据，并对双创类新闻进行加工处理后的数据。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/case02.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">复旦大学</p>
                    <p class="case-name">大数据研究中心</p>
                  </div>
                </div>
                <div class="case-desc">
                  复旦大学大数据研究中心是复旦大学计算机学院旗下的大数据研究平台，擅长自然语言处理，文本分析与挖掘等领域。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/case03.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">四川大学</p>
                    <p class="case-name">舆情研究大数据研究中心</p>
                  </div>
                </div>
                <div class="case-desc">
                  四川大学舆情研究大数据研究中心是四川大学与四川大学文学与新闻传播学院共建的研究基地。
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="case-grids">
          <el-row>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/case04.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">华南理工大学</p>
                    <p class="case-name">新闻数据研究中心</p>
                  </div>
                </div>
                <div class="case-desc">
                  华南理工大学新闻数据研究中心是华南理工大学新闻传播学院旗下大数据研究实验室，擅长市场竞争分析、产品可行性分析、食品安全等领域的舆情研究。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/case05.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">同济大学</p>
                    <p class="case-name">数据挖掘实验室</p>
                  </div>
                </div>
                <div class="case-desc">
                  同济大学数据挖掘实验室是上海萌泰与同济大学软件学院共建的联合技术实验室。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/case06.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">华东师范大学</p>
                    <p class="case-name">调查与数据中心</p>
                  </div>
                </div>
                <div class="case-desc">
                  华东师范大学调查与数据中心是华东师范大学与社会发展学院共建的社会调查研究基地。
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="case-grids">
          <el-row>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/case07.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">腾讯网</p>
                    <p class="case-name">广告与媒体事业部</p>
                  </div>
                </div>
                <div class="case-desc">
                  锐研云采集平台帮助腾讯网致力于互联网与传播领域的合作研究。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/case08.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">紫金传媒智库</p>
                    <p class="case-name">中国民众经济信心指数</p>
                  </div>
                </div>
                <div class="case-desc">
                  锐研云采集平台是紫金传媒智库大数据研究的长期合作伙伴。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/case09.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">凤凰传媒</p>
                    <p class="case-name">数字媒体事业部</p>
                  </div>
                </div>
                <div class="case-desc">
                  锐研云采集平台帮助包括凤凰传媒在内的传媒企业实现互联网传播价值和影响力的分析。
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <div class="footer-top">
        <div class="social-icons">
          <a class="icon" href="mailto:marketing@monetware.com" target="_blank">
            <img src="../../assets/imgs/xinXi.png" alt="" />
          </a>
          <a class="icon" href="https://weibo.com/ringdata" target="_blank">
            <img src="../../assets/imgs/weiBo.png" alt="" />
          </a>
          <a
            class="icon mr"
            href="javascript:alert('欢迎关注“锐研中国”微信公众号！')"
            target="_blank"
          >
            <img src="../../assets/imgs/weiXin.png" alt="" />
          </a>
        </div>
      </div>
      <div class="footer-middle">
        <div class="bottom-menu">
          <span>锐研•云采集 互联网大数据研究云平台</span>
        </div>
      </div>
      <div class="footer-bottom">
        <p>
          Copyright &copy;
          <a
            href="http://www.monetware.com"
            target="_blank"
            title="数据研究技术服务商"
          >
            上海萌泰数据科技股份有限公司
          </a>
          2019 &copy;版权所有.
          <a href=" http://beian.miit.gov.cn" target="_blank">
            沪ICP备09099644号
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import { goLogin, goRegister } from "@/utils/login";

export default {
  data() {
    return {
      isActive: 2,
      isLogin: false, // 用户是否登录
      showBottom: false,
      userName: "",
      menuBox: false,
      goLogin: goLogin,
      goRegister: goRegister,
    };
  },
  created() {
    /*判断用户是否登录*/
    if (VueCookies.isKey("tokenWeb")) {
      let userInfo = VueCookies.get("userInfo");
      this.userName = userInfo.name;
      if (userInfo && !userInfo.ifLoginSpider) {
        this.httpPost("/user/v1/permission", null).then((res) => {
          if (res && res.code === 0) {
            userInfo.spiderPermission = res.data;
            userInfo.ifLoginSpider = true;
            VueCookies.set("userInfo", JSON.stringify(userInfo));
          }
        });
      }
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
  },
  methods: {
    // 切换导航
    changeActive(current) {
      if (current != 2) {
        this.destroyed();
      }
      this.isActive = current;
    },
    // 监听滚轮事件
    handleScroll: function () {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0) {
        this.showBottom = true;
      } else {
        this.showBottom = false;
      }
    },

    destroyed: function () {
      window.removeEventListener("scroll", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
    },

    // 展开菜单
    showMenu() {
      this.menuBox = !this.menuBox;
    },
  },
};
</script>

<style scoped>
.header-right >>> .el-button {
  padding: 8px 13px;
  margin-left: 30px;
  margin-bottom: 3px;
}
</style>
<style lang="scss" scoped>
#user {
  line-height: 0;
}
.into {
  margin-left: 30px;
  margin-right: 10px;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 13px;
  img {
    vertical-align: middle;
    border-style: none;
    padding-bottom: 4px;
    width: 16px;
  }
}
.bug {
  cursor: pointer;
  width: 35px;
  height: 60px;
  float: right;
  padding-right: 20px;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  a {
    cursor: pointer;
    width: 35px;
    height: 60px;
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
  }
}
.menu {
  font-size: 28px;
  color: #666;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 60px;
}
.menuBox {
  z-index: 1000;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  li {
    cursor: pointer;
    z-index: 1000;
    a {
      &:hover {
        color: #760478;
      }
      &.active {
        color: #760478;
      }
    }
  }
  .menu-setting {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    .register {
      width: 70px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      margin-right: 40px;
    }
  }
}
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: #444;
}
button,
button:focus {
  outline: none;
}
ul,
ol {
  list-style: none;
  padding: 0;
}
.icon-banner {
  max-width: 1200px;
  margin: 0 auto;
}
.container {
  max-width: 1200px !important;
  margin: 0 auto;
}
// 头部
.page-header {
  position: fixed;
  left: 0;
  top: 0;
  height: 60px;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  z-index: 10;
  .navbar-header {
    width: 20%;
    height: 60px;
    line-height: 60px;
    float: left;
    padding: 0px 20px 0px;
    img {
      display: inline-block;
      width: 136px;
      height: 40px;
      margin: 9px 0;
    }
  }
  .header-right {
    float: right;
    text-align: right;
    i {
      color: #606266;
    }
    ul {
      width: 100%;
      height: 60px;
      line-height: 60px;
      overflow: hidden;
      li {
        margin-right: 20px;
        float: left;
        a {
          &.active {
            color: #760478;
          }
        }
        .register {
          font-size: 15px;
          font-weight: 500;
          color: #000;
          margin-left: 20px;
        }
        #userImg {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 5px;
          margin-left: 10px;
        }
        #userName {
          margin-left: 2px;
          display: inline-block;
          padding: 0;
          line-height: 60px;
          font-size: 14px;
          color: #777;
        }
        #userName:hover {
          color: #777;
        }
      }
    }
  }
}

.nav {
  margin-top: 60px;
  .section-header h1.title {
    background: #fff;
    text-align: center;
    color: #000;
    font-size: 34px;
    font-weight: 300;
    margin-bottom: 30px;
  }
  .section-header p.desc {
    text-align: center;
    color: grey;
    font-size: 16px;
    font-weight: 300;
  }
  .section-case {
    padding: 50px 0px 10px;
    .section-header {
      margin-bottom: 50px;
    }
    .case-grids {
      overflow: hidden;
    }
    .cardbox {
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
    }
    .case-card {
      padding: 10px;
      border: 1px solid #fff;
      -webkit-box-shadow: 0 5px 18px 0 rgba(82, 94, 102, 0.15);
      -moz-box-shadow: 0 5px 18px 0 rgba(82, 94, 102, 0.15);
      box-shadow: 0 5px 18px 0 rgba(82, 94, 102, 0.15);
      &:hover {
        border-color: #f27800;
      }
    }
    .case-img img {
      width: 100%;
      height: 168px;
    }
    .case-title .org-name {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .case-title .case-name {
      font-size: 20px;
      margin-bottom: 0px;
    }
    .case-desc {
      //   text-indent: 30px;
      text-align: justify;
      margin-top: 20px;
    }
  }
}
.mr {
  margin-right: 0 !important;
}

// 底部
.footer {
  padding: 55px 10px;
  text-align: center;
  background: #eee;
  .social-icons img {
    display: inline-block;
    width: 25px;
    height: 25px;
  }
  .social-icons a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    display: inline-block;
    background: #bdbdbd;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 45px;
    margin-right: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    &:hover {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -webkit-filter: alpha(opacity=80);
      -moz-opacity: 0.8;
      -khtml-opacity: 0.8;
    }
  }
  .footer-middle {
    color: #a0a0a0;
    padding: 10px 0px;
    font-size: 12px;
  }
  .footer-bottom {
    font-size: 12px;
    p {
      color: #a0a0a0;
    }
    a {
      color: #337ab7;
    }
  }
}
</style>
