<template>
  <el-container class="container-usercenter">
    <el-header>
      <div class="header">
        <img src="../assets/imgs/logo.png" @click="$router.go(-1)" alt="" />
      </div>
    </el-header>
    <el-main>
      <div class="content">
        <el-row>
          <el-col :span="5" class="left-menu">
            <div class="avatar" style="margin-top: 31px"></div>
            <div class="wrapper-menu">
              <ul class="menu">
                <li
                  :class="showWhat == 1 ? 'menu-item active' : ''"
                  @click="clickMenu(1)"
                >
                  1.关于我们
                </li>
                <li
                  :class="showWhat == 2 ? 'menu-item active' : ''"
                  @click="clickMenu(2)"
                >
                  2.隐私保护政策
                </li>
                <li
                  :class="showWhat == 3 ? 'menu-item active' : ''"
                  @click="clickMenu(3)"
                >
                  3.锐研用户协议
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="18" class="right-menu">
            <div class="main" v-if="showWhat == 1">
              <div class="title">关于我们</div>
              <div class="content">
                <div>
                  上海萌泰数据科技股份有限公司是国内领先的数据科学技术服务商，专注于社会科学研究领域调查研究方法、技术和数据产品研发，致力于推动社会科学研究方法在其他各行业的应用，通过建设基于云计算的数据采集、统计、分析及挖掘平台，为政府、教育、媒体、医疗、金融等行业客户提供一站式数据科学技术、咨询和运营服务。
                </div>
                <div>
                  锐研数据平台(RINGDATA.COM)是萌泰科技旗下的社会科学专题数据库平台，集成各类科研数据采集和分析研究工具，并可提供定制化的科研服务。锐研数据平台由萌泰科技旗下全资子公司南京锐研软件科技有限公司负责运营。
                </div>
                <br />
                更多详情 >>（
                <a
                  href="http://www.monetware.com/page/introduction.html"
                  target="blank"
                >
                  http://www.monetware.com/page/introduction.html
                </a>
                )
              </div>
            </div>
            <div class="main" v-if="showWhat == 2">
              <div class="title">隐私保护政策</div>
              <div class="content">
                为切实保护用户隐私权，优化用户体验，上海萌泰数据科技股份有限公司及其全资子公司南京锐研软件科技有限公司根据现行法规及政策，制定本《隐私保护政策》（以下简称“本政策”）。
                本政策将详细说明锐研在获取、管理及保护用户个人信息方面的政策及措施。本政策适用于锐研向您提供的所有服务，无论您是通过计算机设备、
                移动终端或其他设备获得的锐研服务。
                <br />
                <span style="font-weight: 500; font-size: 20px">
                  个人信息的收集
                </span>
                <br />
                您已知悉且同意，在您注册锐研帐号或使用锐研提供的服务时，锐研将记录您提供的相关个人信息，如：姓名、邮箱、
                手机号码等，上述个人信息是您获得锐研提供服务的基础。同时，基于优化用户体验之目的，锐研会获取与提升锐研服务有关的其他信息，
                例如当您访问锐研时，我们可能会收集哪些服务的受欢迎程度、浏览器软件信息等以便优化我们的服务。
                <br />
                <span style="font-weight: 500; font-size: 20px">
                  个人信息的管理
                </span>
                <br />
                为了向您提供更好的服务或产品，锐研会在下述情形使用您的个人信息：
                <br />
                1)根据相关法律法规的要求；
                <br />
                2)根据您的授权；
                <br />
                3)根据锐研相关服务条款、应用许可使用协议的约定。
                <br />
                此外，您已知悉并同意：在现行法律法规允许的范围内，锐研可能会将您非隐私的个人信
                息用于市场营销，使用方式包括但不限于：在锐研平台中向您展示或提供广告和促销资料，向您通告或推荐锐研的服务或产品信息，以及其他
                此类根据您使用锐研服务或产品的情况所认为您可能会感兴趣的信息。
                <br />
                未经您本人允许，锐研不会向任何第三方披露您的个人信息，下列情形除外：
                <br />
                1)锐研已经取得您的授权；
                <br />
                2)司法机关或行政机关给予法定程序要求锐研披露的；
                <br />
                3)锐研为维护自身合法权益而向用户提起诉讼或仲裁时；
                <br />
                4)根据您与锐研相关服务条款、应用许可使用协议的约定；
                <br />
                5)法律法规规定的其他情形。
                <br />
                <span style="font-weight: 500; font-size: 20px">
                  个人信息的保护
                </span>
                <br />
                锐研将尽一切合理努力保护其获得的用户个人信息。为防止用户个人信息在意外的、未经授权的情况下被非法访问、复制、
                修改、传送、遗失、破坏、处理或使用，锐研已经并将继续采取以下措施保护您的个人信息：
                <br />
                1)以适当的方式对用户的个人信息进行加密处理；
                <br />
                2)在适当的位置使用密码对用户个人信息进行保护；
                <br />
                3)限制对用户个人信息的访问；
                <br />
                4)其他的合理措施
                <br />
                尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但锐研仍然无法
                保证您的个人信息通过不安全途径进行交流时的安全性。因此，用户个人应采取积极措施保证个人信息的安全，如：定期修改帐号密码，不将自己
                的帐号密码等个人信息透露给他人。
                <br />
                您知悉：锐研提供的个人信息保护措施仅适用于锐研平台，一旦您离开锐研，浏览或使用其他网
                站、服务及内容资源，锐研即没有能力及义务保护您在锐研以外的网站提交的任何个人信息，无论您登录或浏览上述网站是否基于锐研的链接或引导。
                <br />
                <span style="font-weight: 500; font-size: 20px">
                  个人信息的访问
                </span>
                <br />
                当您完成锐研的帐号注册后，您可以查阅或修改您提交给锐研的个人信息。一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别的考虑，您可能无法修改注册时提供的某些初始注册信息及验证信息。
                <br />
                <span style="font-weight: 500; font-size: 20px">
                  对Cookie的使用
                </span>
                <br />
                Cookie可以帮助网站辨认注册用户，计算用户数量，通常被各网站用来判定完成注册的用户是否已经实现登录。锐研承诺，对cookie信息的研究仅用于提升服务/产品质量及优化用户体验之目的。同时，如不希望个人信息保留在cookie中，您可以对浏览器进行配置：选择"拒绝cookie"
                或"当网站发送cookie时通知您"，您知道，鉴于部分锐研的服务可能通过支持cookie来是实现的，完成关闭cookie的操作后，可能影响到您访问锐研或不能充分取得锐研的服务。
                <br />
                <span style="font-weight: 500; font-size: 20px">
                  隐私保护政策的修改
                </span>
                <br />
                锐研公司有权对隐私保护政策进行随时修改，隐私政策修改后，可能需要您进行再次确认和同意，若您不同意修改后的隐私保护政策，可能导致您无法正常使用锐研提供的服务。
              </div>
            </div>
            <div class="main" v-if="showWhat == 3">
              <div class="title">锐研用户协议</div>
              <div class="content">
                <span style="font-weight: 500; font-size: 20px">
                  特别提示：
                </span>
                <br />
                锐研数据平台以及锐研系列软件产品的知识产权由上海萌泰数据科技股份有限公司所有，南京锐研软件科技有限公司提供SaaS运营服务。南京锐研软件科技有限公司是上海萌泰数据科技股份有限公司的全资子公司，为了方便，以下提到“锐研公司”即代表上海萌泰数据科技股份有限公司和南京锐研软件科技有限公司。
                <br />
                您在使用锐研公司提供的各项服务之前，请您务必审慎阅读、充分理解本协议各条款内容，包括但不限于免除或者限制责任的条款。如您不同意本服务协议及/或随时对其的修改，您可以主动停止使用锐研公司提供的服务；您一旦使用锐研公司提供的服务，即视为您已了解并完全同意本服务协议各项内容，包括锐研公司对服务协议随时所做的任何修改，并成为我们的用户。
                <br />
                <span style="font-weight: 500; font-size: 20px">一、总则</span>
                <br />
                1.
                用户可以使用锐研各个产品的单项服务，当用户使用锐研各单项服务时，用户的使用行为视为其对该单项服务的服务条款以及锐研在该单项服务中发出的各类公告的同意。
                <br />
                2.
                锐研用户协议以及各个产品单项服务条款和公告可由锐研公司随时更新，且无需另行通知。您在使用相关服务时,应关注并遵守其所适用的相关条款。
                <br />
                <span style="font-weight: 500; font-size: 20px">
                  二、帐号注册与使用
                </span>
                <br />
                您使用部分锐研产品或服务时需要注册锐研帐号，当您注册和使用锐研帐号时应遵守下述要求：
                <br />
                1.
                用户注册成功后，锐研公司将给予每个用户一个用户帐号，用户可以自主设置帐号密码。该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
                <br />
                2.
                您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，除锐研平台的具体产品对帐户有单独的注册要求外，您可获得锐研平台（ringdata.net网站及客户端）帐号并成为锐研用户，可通过锐研帐户使用锐研平台的各项产品和服务(部分服务需要您首先进行授权)。
                <br />
                3.
                为了方便您在锐研产品中享有一致性的服务，如您已经在锐研数据平台中登录锐研帐号，在您首次使用其他锐研产品时可能需要您预先授权，即锐研平台的账号可以用作锐研其他产品或服务的账号。此环节并不会额外收集、使用您的个人信息。
                <br />
                4.
                锐研帐号（即锐研用户ID）的所有权归锐研公司，用户按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可获得锐研帐号并成为用户。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实或更新不及时而引发的相关问题，锐研公司不负任何责任。用户应当通过真实身份信息认证注册帐号，且用户提交的帐号名称、头像和简介等注册信息中不得出现违法和不良信息，经锐研公司审核，如存在上述情况，锐研公司将不予注册；同时，在注册后，如发现用户以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法和不良信息的，锐研公司有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。
                <br />
                5.
                锐研帐号包括帐户名称和密码，您可使用设置的帐户名称（包括用户名、手机号、邮箱）和密码登录；在帐号使用过程中，为了保障您的帐号安全基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。
                <br />
                6.
                用户不应将其帐号、密码转让、出售或出借予他人使用，帐号使用权仅属于初始申请注册人，用户应对帐号内的全部行为独立承担全部责任。如因帐号转让、买卖或出借导致的帐号纠纷或锐研无法判断帐号归属等情况，锐研有权拒绝提供帐号找回，并视情况采取冻结帐号、封禁帐号等措施。
                <br />
                7.
                因您个人原因导致的帐号无法使用，如需找回锐研帐号，请按照锐研帐号找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求或帐号的唯一凭证不再有效，无法通过锐研公司安全验证，因存在帐号被盗等安全风险导致无法判断帐号归属，锐研公司有权拒绝提供帐号找回服务，并视情况采取冻结帐号、封禁帐号等措施。您锐研帐号下绑定的手机号如因您个人原因停止使用，请尽快更改锐研帐号的绑定手机，如因未及时换绑手机号导致帐号丢失或个人信息泄露的责任由您自行承担，锐研公司有权拒绝提供帐号找回服务，并视情况采取冻结帐号、封禁帐号等措施。
                <br />
                8.
                在需要终止使用锐研帐号服务时，符合以下条件的，您可以申请注销您的锐研帐号。
                <br />
                （1）您仅能申请注销您本人的帐号，并依照锐研公司的流程进行注销；
                <br />
                （2）您仍应对您在注销帐号前且使用锐研公司服务期间的行为承担相应责任，同时锐研公司仍可保存您注销前的相关信息；
                <br />
                （3）注销成功后，帐号信息、个人身份证信息、交易记录、会员权益等将无法恢复或提供；
                <br />
                9.
                我们将按照锐研公司的隐私权保护声明使用您在注册锐研帐户、使用锐研服务中提交、留存的信息。您保证在您使用锐研公司的所有产品和服务前，已经充分了解并同意锐研公司可以据此处理用户信息。
                <br />
                <span style="font-weight: 500; font-size: 20px">
                  三、使用规则
                </span>
                <br />
                1.用户在使用锐研公司的服务时，必须遵守《网络安全法》等中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      showWhat: 1, //显示哪一个
    };
  },
  mounted() {},
  methods: {
    /**
     * 点击左边菜单栏
     */
    clickMenu(value) {
      this.showWhat = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-usercenter {
  .el-header {
    padding: 0;
  }
  ul {
    padding: 0;
    margin-bottom: 0;
    li {
      list-style: none;
    }
  }
  .header {
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding-left: 10px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ccc;
    img {
      width: 220px;
      height: 55px;
    }
  }
  .el-main {
    background: #fff;
  }
  .el-footer {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }

  .left-menu {
    .avatar {
      text-align: center;

      img {
        border-radius: 50%;
        width: 150px;
        height: 150px;
      }

      p {
        text-align: center;
        font-size: 16px;
      }
    }

    .wrapper-menu {
      width: 180px;
      border: 1px solid #ddd;
      background: #f6f6f6;

      h3 {
        background: #e0e0e0;
        padding: 15px 0px 15px 30px;
        margin: 0px;
        font-size: 14px;
        font-weight: normal;
      }

      li {
        padding: 10px 0px 10px 30px;
        cursor: pointer;

        & + li {
          border-top: 1px solid #ddd;
        }

        &.active {
          background: #760478;
          color: #fff;
        }
      }
    }
  }

  .right-menu {
    .main {
      width: 100%;
      margin-top: 18px;

      .title {
        font-size: 24px;
        color: #760478;
        font-weight: 500;
        width: 100%;
        height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #d0d0d0;
      }

      .content {
        padding-top: 30px;
        width: 100%;
        //   margin-left: 4%;
        font-size: 15px;
        line-height: 40px;
        color: #666;
        text-align: justify;
      }
    }
  }
}
</style>
