<template>
  <div class="outside">
    <!-- 头部 -->
    <div
      class="page-header"
      :style="{ borderBottom: showBottom == true ? '1px solid #ccc' : 'none' }"
    >
      <el-row>
        <el-col :span="12">
          <div class="navbar-header">
            <router-link to="/index">
              <img src="../../assets/imgs/logo.png" />
            </router-link>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="bug hidden-sm-and-up">
            <a @click="showMenu"><i class="el-icon-menu menu"></i></a>
          </div>
          <div class="header-right hidden-xs-only">
            <ul>
              <router-link to="/index">
                <li @click="changeActive(1)">
                  <a :class="{ active: isActive == 1 }" href="#">产品</a>
                </li>
              </router-link>

              <router-link to="/case">
                <li @click="changeActive(2)">
                  <a :class="{ active: isActive == 2 }" href="#">案例</a>
                </li>
              </router-link>

              <router-link to="/pricing">
                <li @click="changeActive(3)">
                  <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
                </li>
              </router-link>
              <li>
                <div id="user" v-if="isLogin">
                  <router-link to="/home">
                    <span class="into">
                      进入系统
                      <i class="el-icon-right"></i>
                    </span>
                  </router-link>
                  <i class="iconfont icon-user"></i>
                  <a id="userName">{{ userName }}</a>
                </div>
                <div v-else>
                  <el-button @click="goRegister" class="register">
                    注册
                  </el-button>

                  <el-button type="primary" @click="goLogin" class="login">
                    登录
                  </el-button>
                </div>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="menuBox" v-show="menuBox">
      <ul>
        <router-link to="/index">
          <li @click="changeActive(1)">
            <a :class="{ active: isActive == 1 }" href="#">产品</a>
          </li>
        </router-link>

        <router-link to="/case">
          <li @click="changeActive(2)">
            <a :class="{ active: isActive == 2 }" href="#">案例</a>
          </li>
        </router-link>

        <router-link to="/pricing">
          <li @click="changeActive(3)">
            <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
          </li>
        </router-link>
      </ul>
    </div>

    <!-- 主体区域 -->
    <div class="nav container">
      <div class="section-plan">
        <div class="section-header">
          <h1 class="title">多版本选择，按需定制</h1>
          <p class="desc">无需硬件投入，降低数据采集成本，轻松玩转网络爬虫</p>
        </div>
        <!-- 版本服务项 -->
        <table
          v-if="!isToMore"
          class="version"
          style="table-layout: fixed; margin-right: 8px"
          width="100%"
          cellspacing="0"
          cellpadding="2"
        >
          <tr class="th" style="line-height: 28px">
            <td>
              版本
              <br />
              定价
            </td>
            <td>
              体验版
              <br />
              <span style="color: #5eaf10; font-size: 20px">免费</span>
            </td>
            <td>
              标准版
              <br />
              <span class="text">￥1980</span>
              <span style="font-size: 14px">/年</span>
            </td>
            <td>
              高级版
              <br />
              <span class="text">￥2980</span>
              <span style="font-size: 14px">/年</span>
            </td>
            <td>
              定制版
              <br />
              <span style="color: #f2832a; font-size: 20px">按需定制</span>
            </td>
          </tr>
          <tr style="background-color: #e5e5e5">
            <td colspan="5" class="base1">各版本功能区别</td>
            <td colspan="4" class="base2">各版本功能区别</td>
          </tr>
          <tr>
            <td>采集数量限制</td>
            <td>100条/项目</td>
            <td>1万条/项目</td>
            <td>10万条/项目</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>免费爬虫模板数量</td>
            <td>1个</td>
            <td>10个</td>
            <td>20个</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>数据存储时间</td>
            <td>一年</td>
            <td>有效期内永久</td>
            <td>有效期内永久</td>
            <td>永久</td>
          </tr>
          <tr>
            <td>存储空间</td>
            <td>1G</td>
            <td>30G</td>
            <td>100G</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>项目数</td>
            <td>5</td>
            <td>50</td>
            <td>100</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>采集任务并发数</td>
            <td>1个</td>
            <td>5个</td>
            <td>50个</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>采集结果导出</td>
            <td>
              <el-icon size="18" color="#f2832a"><SuccessFilled /></el-icon>
            </td>
            <td>
              <el-icon size="18" color="#f2832a"><SuccessFilled /></el-icon>
            </td>
            <td>
              <el-icon size="18" color="#f2832a"><SuccessFilled /></el-icon>
            </td>
            <td>
              <el-icon size="18" color="#f2832a"><SuccessFilled /></el-icon>
            </td>
          </tr>
          <tr>
            <td>采集执行策略</td>
            <td>无</td>
            <td>无</td>
            <td>有</td>
            <td>有</td>
          </tr>
          <tr>
            <td>单项目策略数</td>
            <td>无</td>
            <td>无</td>
            <td>5个</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>采集任务优先级</td>
            <td>低</td>
            <td>中</td>
            <td>高</td>
            <td>最高</td>
          </tr>
          <tr>
            <td>分布式集群采集</td>
            <td></td>
            <td></td>
            <td>
              <el-icon size="18" color="#f2832a"><SuccessFilled /></el-icon>
            </td>
            <td>
              <el-icon size="18" color="#f2832a"><SuccessFilled /></el-icon>
            </td>
          </tr>
          <tr>
            <td>私有云部署</td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <el-icon size="18" color="#f2832a"><SuccessFilled /></el-icon>
            </td>
          </tr>
          <tr class="end">
            <td></td>
            <td>
              <el-button
                style="border-color: #f27800; color: #f27800"
                @click="onRegister"
                round
              >
                立即体验
              </el-button>
            </td>
            <td>
              <el-button
                style="border-color: #f27800; color: #f27800"
                @click="onCreateOrder(2)"
                round
              >
                立即购买
              </el-button>
            </td>
            <td>
              <el-button
                style="border-color: #f27800; color: #f27800"
                @click="onCreateOrder(3)"
                round
              >
                立即购买
              </el-button>
            </td>
            <td>
              <el-button
                style="border-color: #f27800; color: #f27800"
                round
                @click="centerDialogVisible = true"
              >
                立即咨询
              </el-button>
            </td>
          </tr>
        </table>
        <!-- 2个套餐展示框 -->
        <div class="price" v-if="!isToMore">
          <div class="title">套餐定价</div>
          <div class="content">
            <table
              style="table-layout: fixed"
              width="100%"
              cellspacing="0"
              cellpadding="2"
            >
              <tr>
                <td class="tit">套餐名</td>
                <td class="tit">说明</td>
                <td class="tit">售价</td>
                <td rowspan="3">
                  <el-button type="warning" class="btn" @click="toMore">
                    更多套餐
                  </el-button>
                </td>
              </tr>
              <tr>
                <td>统计调查套餐</td>
                <td>云调查+云统计</td>
                <td>
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                      <div>
                        <span class="yuan">￥3980</span>
                        <span>/年</span>
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                      <div class="original">
                        原价：
                        <span class="ins">￥4960/年</span>
                      </div>
                    </el-col>
                  </el-row>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>小数据套餐</td>
                <td>云调查+云访谈</td>
                <td>
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                      <div>
                        <span class="yuan">￥4780</span>
                        /年
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                      <div>
                        <span class="original">
                          原价：
                          <span class="ins">￥5960/年</span>
                        </span>
                      </div>
                    </el-col>
                  </el-row>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
        <!-- 展开更多套餐-->
        <div v-if="isToMore">
          <div class="toback">
            <div @click="toBack" class="back">
              <i class="el-icon-back"></i>
              返回
            </div>
          </div>
          <table
            class="more"
            style="table-layout: fixed"
            border="1"
            width="100%"
            cellspacing="0"
            cellpadding="2"
          >
            <tr class="th lh">
              <td colspan="4">套餐定价</td>
            </tr>
            <tr>
              <td style="font-size: 18px">套餐名</td>
              <td style="font-size: 18px">说明</td>
              <td>标准版</td>
              <td>高级版</td>
            </tr>
            <tr>
              <td>统计调查套餐</td>
              <td>云调查+云统计</td>
              <td>
                ￥2380
                <span class="year">/年</span>
                <div>
                  <span class="original">
                    原价：
                    <span class="ins">￥2960/年</span>
                  </span>
                </div>
              </td>
              <td>
                ￥3980
                <span class="year">/年</span>
                <div>
                  <span class="original">
                    原价：
                    <span class="ins">￥4960/年</span>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>小数据研究套餐</td>
              <td>云调查+云访谈</td>
              <td>
                ￥3180
                <span class="year">/年</span>
                <div>
                  <span class="original">
                    原价：
                    <span class="ins">￥3960/年</span>
                  </span>
                </div>
              </td>
              <td>
                ￥4780
                <span class="year">/年</span>
                <div>
                  <span class="original">
                    原价：
                    <span class="ins">￥5960/年</span>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>大数据研究套餐</td>
              <td>云采集+云文析</td>
              <td>
                ￥3180
                <span class="year">/年</span>
                <div>
                  <span class="original">
                    原价：
                    <span class="ins">￥3960/年</span>
                  </span>
                </div>
              </td>
              <td>
                ￥4780
                <span class="year">/年</span>
                <div>
                  <span class="original">
                    原价：
                    <span class="ins">￥5960/年</span>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>数据分析套餐</td>
              <td>云统计+云文析</td>
              <td>
                ￥2380
                <span class="year">/年</span>
                <div>
                  <span class="original">
                    原价：
                    <span class="ins">￥2960/年</span>
                  </span>
                </div>
              </td>
              <td>
                ￥3980
                <span class="year">/年</span>
                <div>
                  <span class="original">
                    原价：
                    <span class="ins">￥4960/年</span>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>社会科学研究工具套餐</td>
              <td>云调查+云统计+云采集+云文析+云访谈</td>
              <td>
                ￥5880
                <span class="year">/年</span>
                <div>
                  <span class="original">
                    原价：
                    <span class="ins">￥8900/年</span>
                  </span>
                </div>
              </td>
              <td>
                ￥8880
                <span class="year">/年</span>
                <div>
                  <span class="original">
                    原价：
                    <span class="ins">￥13900/年</span>
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <!-- 手机端更多按钮 -->
        <div class="more-btn" v-if="!isToMore">
          <el-row type="flex" justify="center">
            <el-button type="warning" class="btn" @click="toMore">
              更多套餐
            </el-button>
          </el-row>
        </div>
        <!-- 立即咨询弹框 -->
        <el-dialog
          title="立即咨询"
          :visible.sync="centerDialogVisible"
          width="30%"
          center
        >
          <p style="font-size: 18px; font-weight: 400">
            如果您想进一步了解，请联系我们
          </p>
          <p>
            服务热线：
            <span style="color: #f2832a; font-size: 18px">4006-213-056</span>
          </p>
          <p>
            南京客户支持中心：
            <span style="color: #f2832a; font-size: 18px">025-86207696</span>
          </p>
          <span slot="footer" class="dialog-footer"></span>
        </el-dialog>
        <div class="section-bottom">
          上海萌泰数据科技股份有限公司（锐研•云采集）保留随时对以上各个套餐做出调整的权利。
        </div>
      </div>

      <div class="section-question">
        <h3>
          <i class="fa fa-question-circle-o"></i>
          问题与解答
        </h3>

        <div class="question-grids">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div>
                <h4 class="question">什么是公有云，什么是私有云?</h4>
                <p>
                  简单的讲，公有云就是大家都可以使用的云平台，比如163邮箱，而私有云是只有您能够使用的云平台，比如为您独立部署的云采集平台。
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div>
                <h4 class="question">什么是爬虫模板？如何编写爬虫模板？</h4>
                <p>
                  针对不同类型的网页页面，我们需要配置不同的爬虫模板，通过爬虫模板可以采集具有同一类布局的网页内容。锐研·云采集平台提供了可视化的爬虫模板编辑功能。
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div>
                <h4 class="question">使用云采集平台，还需要安装爬虫软件吗？</h4>
                <p>
                  我们的云采集平台完全基于云计算架构，用户不需要下载安装任何爬虫客户端程序，只需要在浏览器上进行配置即可完成数据的采集。
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div>
                <h4 class="question">
                  爬虫采集的时候，是否需要一直观察和等待？
                </h4>
                <p>
                  不需要！爬虫在云端执行自动化采集，一旦配置好爬虫模板，启动爬虫任务后，可放心不管，任务采集完成后，可邮件或短信通知您。
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div>
                <h4 class="question">如何购买？</h4>
                <p>
                  我们提供公有云租用和私有云独立部署两种方式。注册成功后，我们的业务人员会及时与您取得联系。
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div>
                <h4 class="question">更多问题，请联系我们</h4>
                <p>
                  可通过电话，邮件，微博，微信以及QQ联系我们。
                  <br />
                  服务热线
                  <b>4006-213-056</b>
                  <br />
                  南京客户支持中心
                  <b>025-86207696</b>
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <div class="footer-top">
        <div class="social-icons">
          <a class="icon" href="mailto:marketing@monetware.com" target="_blank">
            <img src="../../assets/imgs/xinXi.png" alt="" />
          </a>
          <a class="icon" href="https://weibo.com/ringdata" target="_blank">
            <img src="../../assets/imgs/weiBo.png" alt="" />
          </a>
          <a
            class="icon mr"
            href="javascript:alert('欢迎关注“锐研中国”微信公众号！')"
            target="_blank"
          >
            <img src="../../assets/imgs/weiXin.png" alt="" />
          </a>
        </div>
      </div>
      <div class="footer-middle">
        <div class="bottom-menu">
          <span>锐研•云采集 互联网大数据研究云平台</span>
        </div>
      </div>
      <div class="footer-bottom">
        <p>
          Copyright &copy;
          <a
            href="http://www.monetware.com"
            target="_blank"
            title="数据研究技术服务商"
          >
            上海萌泰数据科技股份有限公司
          </a>
          2019 &copy;版权所有.
          <a href=" http://beian.miit.gov.cn" target="_blank">
            沪ICP备09099644号
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import { goLogin, goRegister } from "@/utils/login";
import { httpPostIsAnonymous, httpPost } from "@/api/httpService.js";

export default {
  data() {
    return {
      centerDialogVisible: false, //立即咨询弹框
      isToMore: false, //更多套餐
      isActive: 3,
      isLogin: false, // 用户是否登录
      showBottom: false,
      userName: "",
      menuBox: false,
      goLogin: goLogin,
      goRegister: goRegister,
    };
  },
  created() {
    /*判断用户是否登录*/
    if (VueCookies.isKey("tokenWeb")) {
      let userInfo = VueCookies.get("userInfo");
      this.userName = userInfo.name;
      if (userInfo && !userInfo.ifLoginSpider) {
        httpPost("/user/v1/permission", null).then((res) => {
          if (res && res.code === 0) {
            userInfo.spiderPermission = res.data;
            userInfo.ifLoginSpider = true;
            VueCookies.set("userInfo", JSON.stringify(userInfo));
          }
        });
      }
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
    // this.getPermission();
  },
  methods: {
    /**
     * 更多套餐
     */
    toMore() {
      this.isToMore = true;
    },
    /**
     * 返回
     */
    toBack() {
      this.isToMore = false;
    },
    /*获取权益*/
    // getPermission() {
    //   httpPost("/out/getPermission").then((result) => {
    //     if (result.code == 0) {
    //       let tempList = result.data;
    //       for (let i = 0; i < tempList.length; i++) {
    //         if (tempList[i].maxTextNum >= 10000) {
    //           tempList[i].maxTextNum =
    //             tempList[i].maxTextNum / 10000.0 + "万条";
    //         } else {
    //           tempList[i].maxTextNum = tempList[i].maxTextNum + "条";
    //         }
    //       }
    //       this.resultList = result.data;
    //     }
    //   });
    // },

    /*点击立即体验事件*/
    onRegister() {
      this.$router.push({
        path: "/register",
      });
    },

    /*点击立即购买事件*/
    onCreateOrder(type) {
      if (this.isLogin) {
        if (type === 2) {
          window.open("https://www.ringdata.com/createorder?st=19");
        } else if (type === 3) {
          window.open("https://www.ringdata.com/createorder?st=20");
        }

        // this.httpPost('/order/create', {
        //   type: type,
        // }).then((result) => {
        //   if (result.code == 0) {
        //     // 跳转支付页面
        //     this.$router.push({
        //       path: '/pay',
        //       query: {
        //         id: result.data,
        //       },
        //     })
        //   } else {
        //     this.$message({
        //       type: 'error',
        //       message: result.message,
        //     })
        //   }
        // })
      } else {
        this.$message.info("您还未登录，请登录后购买使用");
      }
    },

    // 切换导航
    changeActive(current) {
      if (current != 3) {
        this.destroyed();
      }
      this.isActive = current;
    },
    // 监听滚轮事件
    handleScroll: function () {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0) {
        this.showBottom = true;
      } else {
        this.showBottom = false;
      }
    },

    destroyed: function () {
      window.removeEventListener("scroll", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
    },

    // 展开菜单
    showMenu() {
      this.menuBox = !this.menuBox;
    },
  },
};
</script>

<style scoped>
.header-right >>> .el-button {
  padding: 8px 13px;
  margin-left: 30px;
  margin-bottom: 3px;
}
</style>
<style lang="scss" scoped>
.mr {
  margin-right: 0 !important;
}
.ml {
  margin-left: 40px !important;
}
#user {
  line-height: 0;
}
.bug {
  cursor: pointer;
  width: 35px;
  height: 60px;
  float: right;
  padding-right: 20px;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  a {
    cursor: pointer;
    width: 35px;
    height: 60px;
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
  }
}
.menu {
  font-size: 28px;
  color: #666;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 60px;
}
.menuBox {
  z-index: 1000;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  li {
    cursor: pointer;
    z-index: 1000;
    a {
      &:hover {
        color: #760478;
      }
      &.active {
        color: #760478;
      }
    }
  }
  .menu-setting {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    .register {
      width: 70px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      margin-right: 40px;
    }
  }
}
.into {
  margin-left: 30px;
  margin-right: 10px;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 13px;
  img {
    vertical-align: middle;
    border-style: none;
    padding-bottom: 4px;
    width: 16px;
  }
}
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: #444;
}
button,
button:focus {
  outline: none;
}
ul,
ol {
  list-style: none;
  padding: 0;
}
.icon-banner {
  max-width: 1200px;
  margin: 0 auto;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
// 头部
.page-header {
  position: fixed;
  left: 0;
  top: 0;
  height: 60px;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  z-index: 10;
  .navbar-header {
    width: 20%;
    height: 60px;
    line-height: 60px;
    float: left;
    padding: 0px 20px 0px;
    img {
      display: inline-block;
      width: 136px;
      height: 40px;
      margin: 9px 0;
    }
  }
  .header-right {
    float: right;
    text-align: right;
    ul {
      width: 100%;
      height: 60px;
      line-height: 60px;
      overflow: hidden;
      li {
        margin-right: 20px;
        float: left;
        a {
          &.active {
            color: #760478;
          }
        }
        .register {
          font-size: 15px;
          font-weight: 500;
          color: #000;
          margin-left: 20px;
        }
        i {
          color: #606266;
        }
        #userImg {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 5px;
          margin-left: 10px;
        }
        #userName {
          display: inline-block;
          padding: 0;
          margin-left: 2px;
          line-height: 60px;
          font-size: 14px;
          color: #777;
        }
        #userName:hover {
          color: #777;
        }
      }
    }
  }
}
// 主体
.nav {
  padding: 110px 0px 10px;
  font-weight: 300 !important;
  color: #666;
  max-width: 1200px !important;
  .section-grids {
    overflow: hidden;
  }
  .cardbox {
    width: 100%;
    padding: 10px;
  }
  .section-plan {
    margin-right: 8px;
    margin-left: 8px;
    // 版本价格表格start
    $base-color: #f2832a;
    .btn {
      background: $base-color;
    }
    .btn:hover {
      background: #f89543;
    }
    .more-btn {
      display: none;
    }
    .version {
      width: 100%;
      text-align: center;
      line-height: 40px;
      border: 1px solid #cbcbcb;
      border-collapse: collapse; /* 合并单元格 */
      .base2 {
        display: none;
      }
      td {
        border: 1px solid #cbcbcb;
      }
      .th {
        text-align: center !important;
      }
      .th td {
        padding: 18px 0px;
        background: #fff !important;
        font-size: 20px;
        font-weight: 300;
        color: #000;
        .text {
          color: #f2832a;
          font-size: 20px;
        }
      }
      .end td {
        padding: 12px 0px;
        background: #fff !important;
      }
      tr td:nth-child(3),
      tr td:nth-child(5) {
        background: #f3f3f3;
      }
      //   tr td:nth-child(1){
      //       text-align: left;
      //       padding-left: 12px;
      //   }
      td button:hover {
        background-color: hsl(27, 89%, 82%);
      }
    }

    // 展开更多套餐
    .more {
      width: 100%;
      height: 300px;
      text-align: center;
      border: 1px solid #cbcbcb;
      border-collapse: collapse; /* 合并单元格 */
      margin-bottom: 32px;
      .year {
        font-size: 14px;
        color: #000;
      }
      .th {
        text-align: center !important;
        td {
          padding: 18px 0px;
          background: #fff !important;
          font-size: 20px !important;
          font-weight: 300;
          color: #000;
        }
      }
      tr td:nth-child(3),
      tr td:nth-child(4) {
        font-size: 18px;
        color: $base-color;
        padding: 4px 12px;
      }
      td {
        height: 40px;
        border: 1px solid #cbcbcb;
      }
      .original {
        padding-left: 8px;
        line-height: 18px !important;
        font-size: 14px;
        color: #b3b1b1;
        .ins {
          text-decoration: line-through;
        }
      }
    }
    .el-icon-success {
      color: $base-color;
      font-size: 18px;
    }
    //2个套餐展示框
    .price {
      width: 100%;
      margin: 12px 0px;
      tr td:nth-child(1),
      tr td:nth-child(2),
      tr td:nth-child(3) {
        text-align: left;
        width: 30%;
      }
      tr td:nth-child(4) {
        width: 10%;
      }
      .title {
        margin-bottom: 12px;
      }
      .title,
      .yuan {
        font-size: 18px;
        color: $base-color;
      }
      .content {
        width: 100%;
        padding-top: 12px;
        line-height: 40px;
        padding: 0px 12px;
        border: 1px solid #cbcbcb;
        .tit {
          font-size: 16px;
          font-weight: 600;
          color: #999;
          padding-left: 24px;
        }
        .lh {
          height: 60px;
          line-height: 60px;
        }
      }
      .original {
        font-size: 12px;
        color: #dfdfdf;
        .ins {
          text-decoration: line-through;
        }
      }
    }
    // 更多套餐返回按钮
    .toback {
      .back {
        color: $base-color;
        float: right;
        margin-bottom: 24px;
        cursor: pointer;
        i {
          margin-right: 12px;
        }
      }
    }
    //手机自适应
    @media screen and (max-width: 767px) {
      font-size: 12px !important;
      tr td:nth-child(5) {
        display: none;
      }
      .section-header {
        margin-bottom: 24px !important;
      }
      .version {
        width: 100%;
        line-height: 24px;
        .el-button {
          padding: 2px;
          font-size: 12px !important;
        }
        .base1 {
          display: none;
        }
        tr td:nth-child(1) {
          width: 60px;
        }
      }
      .price {
        margin-bottom: 12px;
        .content {
          line-height: 24px;
        }
        table {
          font-size: 12px;
          tr td:nth-child(4) {
            display: none;
          }
        }
        .btn {
          margin-left: 130%;
        }
      }
      .more {
        .original {
          line-height: 24px;
        }
      }
      .more-btn {
        display: block;
      }
    }
    h1 {
      margin-bottom: 40px;
    }
    .section-header {
      margin-bottom: 60px;
      h1.title {
        background: #fff;
        text-align: center;
        color: #000;
        font-size: 34px;
        font-weight: 300;
        margin-bottom: 30px;
      }
      p.desc {
        text-align: center;
        color: grey;
        font-size: 16px;
        font-weight: 300;
        padding: 10px;
      }
    }
    .section-bottom {
      color: #777;
      margin-bottom: 30px;
      margin-left: 5px;
      padding: 10px;
    }
  }

  .section-question {
    padding-left: 15px;
    padding-right: 30px;
    overflow: hidden;
    h3 {
      margin: 20px 0px 30px;
      font-weight: 400;
      font-size: 24px;
    }
    .question-grids h4 {
      font-weight: 400;
      margin-bottom: 15px;
      font-size: 18px;
    }
    .question-grids p {
      margin-bottom: 35px;
      text-align: justify;
      color: grey;
    }
  }
}
// 底部
.footer {
  padding: 55px 10px;
  text-align: center;
  background: #eee;
  .social-icons img {
    display: inline-block;
    width: 25px;
    height: 25px;
  }
  .social-icons a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    display: inline-block;
    background: #bdbdbd;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 45px;
    margin-right: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    &:hover {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -webkit-filter: alpha(opacity=80);
      -moz-opacity: 0.8;
      -khtml-opacity: 0.8;
    }
  }
  .footer-middle {
    color: #a0a0a0;
    padding: 10px 0px;
    font-size: 12px;
  }
  .footer-bottom {
    font-size: 12px;
    p {
      color: #a0a0a0;
    }
    a {
      color: #337ab7;
    }
  }
}
</style>
